import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import { Nav, Navbar, NavLink, Tabs, Tab } from 'react-bootstrap';
import IframeResizer from 'iframe-resizer-react'
import React, { useState } from 'react';

//import empres_logo from './images/empress-i_plus_logo.svg';
import empres_logo from './images/EMPRESi_typographic_logo.svg';
import fao_logo from './images/FAO_logo_White_2lines_en.svg';
import fao_logo_png from './images/fao-logo.png';

import overview_dark from './images/overview_dark.svg';
import overview_light from './images/overview_light.svg';
import diseases_dark from './images/diseases_dark.svg';
import diseases_light from './images/diseases_light.svg';
import epi_dark from './images/epi_dark.svg';
import epi_light from './images/epi_light.svg';
import directory_dark from './images/directory_dark.svg';
import directory_light from './images/directory_light.svg';
import laboratories_dark from './images/laboratories_dark.svg';
import laboratories_light from './images/laboratories_light.svg';
import libraries_dark from './images/libraries_dark.svg';
import libraries_light from './images/libraries_light.svg';




//className='ms-auto' 
function App() {
  const [key, setKey] = useState('/home');
  return (
    <div className="App" id="main-div">
      <Navbar bg="headerBlue" variant="dark" expand="lg"  bs-class="empres-header"   >
        <Navbar.Brand>
        <a href="http://fao.org">
          <img src={fao_logo_png} className="headerLogo headerLogoSeparator" alt="FAO" />
        </a>
        </Navbar.Brand>    
        <Nav className="justify-content-center" >
          <Nav.Item >
            NDC-AG Navigator
          </Nav.Item>
        </Nav>
      </Navbar>
      <Navbar bg="headerWhite" variant="light" expand="lg" bs-class="empres-header">
        <Nav variant="tabs" defaultActiveKey="/home"  onSelect={(k) => setKey(k)}>
          <Nav.Item >
            <Nav.Link eventKey="/home" >
            <img src={key === "/home"?overview_dark:overview_light} className="tabImage" alt="Overview" />  
            Overview
          </Nav.Link>
          </Nav.Item>
          {/*<Nav.Item> 
            <Nav.Link eventKey="/diseases" >              
              <img src={key === "/diseases"?diseases_dark:diseases_light} className="tabImage" alt="Diseases" />  
              Diseases
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/epidemiology">
            <img src={key === "/epidemiology"?epi_dark:epi_light} className="tabImage" alt="Epidemiology" />  
              Epidemiology
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/directory">
            <img src={key === "/directory"?directory_dark:directory_light} className="tabImage" alt="Epidemiology" /> 
              Directory
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/laboratories">
            <img src={key === "/laboratories"?laboratories_dark:laboratories_light} className="tabImage" alt="Laboratories" /> 
            Laboratories
            </Nav.Link>
          </Nav.Item>
          <Nav.Item> 
            <Nav.Link eventKey="/library" >
            <img src={key === "/library"?libraries_dark:libraries_light} className="tabImage" alt="Libraries" /> 
              Library
            </Nav.Link>
          </Nav.Item>
           <Nav.Item>
            <Nav.Link eventKey="/event_managment">
              Event Managment
            </Nav.Link>
          </Nav.Item>*/}
        </Nav>
      </Navbar>
      <div  className="container">
            {key === "/home" ? 
            <div style={{ minWidth: '100%', heigth: "100%", display:'flex', flex: "1", padding:"0px"}}> 
            <IframeResizer className="iframe-hand-in-hand"
            log
            scrolling='true'
            src="https://data.apps.fao.org/?https://data.apps.fao.org/catalog/dataset/94f15d3e-6f69-4f2d-8a8b-27fe08de3d89/resource/773860e4-6b95-4c39-956f-e47f2b234398/download/ndc-navigator-terriajs-config.json"
            style={{ visibilty:window.location.pathname === "/home" ? "" : "none",  width: '1px', overflow:'scroll', minWidth: '100%', top:'0px', bottom:'0px'}}
            sizeHeight="true"
            sizeWidth="true"
            heightCalculationMethod="max"
            /> 
            </div> : <div></div>}

            {key === "/diseases" ?  
            <div style={{ minWidth: '100%', heigth: "100%", display:'flex', flex: "1", padding:"0px"}}> 
            <IframeResizer className="iframe-hand-in-hand"
            log
            scrolling='true'
            src="https://empresi-shiny-app-dot-fao-empres-re.ew.r.appspot.com/#!/"
            style={{ visibilty:window.location.pathname === "/home" ? "" : "none",  width: '1px', scrolling:'no', minWidth: '100%', top:'0px', bottom:'0px'}}
            sizeHeight="true"
            sizeWidth="true"
            heightCalculationMethod="max"
            />
            </div> : <div></div>}

            {key === "/epidemiology" ?  
            <div style={{ minWidth: '100%', heigth: "100%", display:'flex', flex: "1", padding:"0px"}}>           
            <IframeResizer className="iframe-hand-in-hand"
            log
            scrolling='true'
            src="https://empresi-shiny-app-dot-fao-empres-re.ew.r.appspot.com/#!/epidemiology"
            style={{ visibilty:window.location.pathname === "/home" ? "" : "none",  width: '1px', scrolling:'no', minWidth: '100%', top:'0px', bottom:'0px'}}
            sizeHeight="true"
            sizeWidth="true"
            heightCalculationMethod="max"
            />
            </div> : <div></div>}

            {key === "/directory" ? <p>Coming soon</p> : <div></div>}
            {key === "/laboratories" ? <p>Coming soon</p> : <div></div>}
            {key === "/library" ? <p>comming soon</p> : <div></div>}
            
            {key === "/event_managment" ?  
              <div style={{ minWidth: '100%', heigth: "100%", display:'flex', flex: "1", padding:"0px"}}>           
                <IframeResizer className="iframe-hand-in-hand"
                scrolling='true'
                log
                src="https://fao-empres-re-private.web.app"
                style={{ visibilty:window.location.pathname === "/home" ? "" : "none",  width: '1px', scrolling:'no', minWidth: '100%', top:'0px', bottom:'0px'}}
                sizeHeight="true"
                sizeWidth="true"
                heightCalculationMethod="max"
                />
              </div> : <div></div>}
          <div style={{ minWidth: '100%', heigth: "100%", display:'flex'}}></div>
        </div>
    </div>
  );
}

export default App;
